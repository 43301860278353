import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie'

export const HEALTHCARD_REGEX_VALIDATION = /^\d{10}[A-Z]{0,2}$/
export const PHONENUMBER_REGEX_VALIDATION = /^\d{10}$/
export const EMAIL_REGEX_VALIDATION = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const healthCardFormatter = (healthCard) => {
    return healthCard.length > 10 ? (healthCard).replace(/(\d{4})(\d{3})(\d{3})([A-Z]{1,2}?)/, "$1-$2-$3-$4") : (healthCard).replace(/(\d{4})(\d{3})(\d{3})/, "$1-$2-$3");
}

export const getJWTData = () => {
    const jwtToken = Cookies.get('auth-token');
    return jwt_decode(jwtToken);
}

export const invalidateCookies = () => {
    Cookies.remove("auth-token");
    Cookies.remove("user_id");
    Cookies.remove("clinic_name");
}

export const CLINIC_URLS = [
    'clinic',
    'clinicdemo',
]

export const ADMIN_URLS = [
    'admin',
    'admindemo',
]

export const PATIENT_URLS = [
    "local",
    "patientdemo",
    "drsafrazm",
    "ahc",
    "womenhealthtt",
    "pentest",
]

export const USER_TYPE_STORE = {
    "PATIENT": 1,
    "STAFF": 2,
    "ALOE_ADMIN": 3,
    "CLINIC_ADMIN": 4,
    "ADMINISTRATOR": 5
}

export const MAX_NOTIFICATIONS = 3;

export const SESSION_STATUS = {
    UNAUTHORIZED: -1,
    ACTIVE: 0,
    EXPIRING: 1,
    EXPIRED: 2,
}

export const NON_EXPIRABLE_SESSION_PATHS = [
    '/',
    '/login',
    '/forgot-password',
    '/reset-password',
]

export const SESSION_TIMEOUT_WARNING_TIME = 1000 * 60 * 5;      // 1 millisecond  * 60 seconds per minute * 5 minutes
export const SESSION_EXPIRY_FROM_WARNING_TIME = 1000 * 60 * 2;
export const MAX_LOGIN_ATTEMPTS = 4;
