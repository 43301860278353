import React, { lazy } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

// Patient Views
const PatientLogin = lazy(() => import('./components/Patient/Login/PatientLogin'));
const PatientExpressLogin = lazy(() => import('./components/Patient/ExpressLogin'));
const PatientDashboard = lazy(() => import('./components/Patient/Dashboard/PatientDashboard'));
const PatientQuestionnaire = lazy(() => import('./components/Patient/Questionnaire/PatientQuestionnaire'));

// Clinic Views
const ClinicLogin = lazy(() => import('./components/Clinic/Login'));
const ClinicDashboard = lazy(() => import('./components/Clinic/Dashboard'));
const ClinicPatientProfile = lazy(() => import('./components/Clinic/PatientProfile'));
const ClinicSurveyGroups = lazy(() => import('./components/Clinic/SurveyGroups'));
const ClinicDataAnalysis = lazy(() => import('./components/Clinic/DataAnalysis'));
const Settings = lazy(() => import('./components/Clinic/Settings'));

// Admin Views
const AdminDashboard = lazy(() => import('./components/Admin/Dashboard'));
const ClinicPage = lazy(() => import('./components/Admin/ClinicPage'));

// Not Found View
const NotFound = lazy(() => import('./components/NotFound'));

export const ClinicRoutes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/login" />
            <Route exact path="/login">
                <ClinicLogin adminLogin={false} />
            </Route> 
            <Route exact path="/forgot-password" component={ClinicLogin} />
            <Route exact path="/reset-password" component={ClinicLogin} />
            <Route exact path="/dashboard" component={ClinicDashboard} />
            <Route exact path="/patient-profile" component={ClinicPatientProfile} />
            <Route exact path="/survey-groups" component={ClinicSurveyGroups} />
            <Route exact path="/data-analysis" component={ClinicDataAnalysis} />
            <Route exact path="/settings" component={Settings} />
            <Route path='/not-found' component={NotFound} />
            <Redirect to='/not-found' />
        </Switch>
    )
}

export const AdminRoutes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/login" />
            <Route exact path="/login">
                <ClinicLogin adminLogin={true} />
            </Route>
            <Route exact path="/dashboard" component={AdminDashboard} />
            <Route exact path="/clinic-page" component={ClinicPage} />
            <Route path='/not-found' component={NotFound} />
            <Redirect to='/not-found' />
        </Switch>
    )
}

export const PatientRoutes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/login" />
            <Route exact path="/login" component={PatientLogin} />
            <Route exact path="/express-login" component={PatientExpressLogin} />
            <Route exact path="/dashboard" component={PatientDashboard} />
            <Route exact path="/questionnaire/:questionnaireID" component={PatientQuestionnaire} />
            <Route path='/not-found' component={NotFound} />
            <Redirect to='/not-found' />
        </Switch>
    )
}

export const NotFoundRoutes = () => {
    return (
        <Switch>
            <Route path='/not-found' component={NotFound} />
            <Redirect to='/not-found' />
        </Switch>
    )
}
