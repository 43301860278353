import { SESSION_STATUS } from '../../utils/constants';
import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    sessionStatus: -1
  },
  reducers: {
    setUnauthorized: (state) => {
        state.sessionStatus = SESSION_STATUS.UNAUTHORIZED
    },
    setActive: (state) => {
        state.sessionStatus = SESSION_STATUS.ACTIVE
    },
    setExpiring: (state) => {
        state.sessionStatus = SESSION_STATUS.EXPIRING
    },
    setExpired: (state) => {
        state.sessionStatus = SESSION_STATUS.EXPIRED
    }
  },
})

export const { setUnauthorized, setActive, setExpiring, setExpired } = sessionSlice.actions
export const status = (state) => state.session.sessionStatus

export default sessionSlice.reducer
