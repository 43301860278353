import React, { Suspense, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { CLINIC_URLS, ADMIN_URLS, PATIENT_URLS, SESSION_STATUS, NON_EXPIRABLE_SESSION_PATHS, invalidateCookies, MAX_NOTIFICATIONS, SESSION_TIMEOUT_WARNING_TIME } from '../src/utils/constants'
import { ClinicRoutes, AdminRoutes, PatientRoutes, NotFoundRoutes } from './routes';

import {themeGlobal} from './assets/globalTheme'
import { SnackbarProvider } from 'notistack';

import AloeLogo from './assets/images/justlogo.svg'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AloeLoading from './components/Common/AloeLoading'
import ExpiredSessionModal from './components/Common/ExpiredSessionModal'

import { useIdleTimer } from 'react-idle-timer'
import { useSelector, useDispatch } from 'react-redux';
import { setActive, setExpiring, setExpired, status, setUnauthorized } from './Features/Session/sessionSlice';
import { SESSION_EXPIRY_FROM_WARNING_TIME } from './utils/constants';

const useStyles = makeStyles(() => ({
    success: { 
        backgroundColor: 'white !important', 
        color: 'rgb(26, 179, 148) !important',
        borderRadius: "16px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.7)",
        lineHeight: "2em",
        width: 'fit-content',
        minWidth: '10px',
    },
    error: { 
        backgroundColor: 'white  !important', 
        color: "#EB5757 !important",
        borderRadius: "16px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.7)",
        width: 'fit-content',
    },
    warning: { 
        backgroundColor: 'white  !important', 
        color: "yellow  !important",
        border: "2px solid yellow",
        borderRadius: "16px"
    },
    info: { 
        backgroundColor: 'white  !important', 
        color: "blue  !important",
        border: "2px solid blue",
        borderRadius: "16px"
    },
    blurBackground: {
        filter: "blur(4px) !important",
    }
}));

const App = () => {

    let history = useHistory();
    const classes= useStyles();
    const dispatch = useDispatch();
    const sessionStatus = useSelector(status);

    const get_identifier = () => {
        const host = window.location.host.split('.')[0];

        if (host === "www") {
            return (window.location.host.split('.')[1])
        } else {
            return host
        }
    }

    const routes = () => {

        const identifier = get_identifier()

        if (CLINIC_URLS.includes(identifier)) {
            return <ClinicRoutes />
        } else if (PATIENT_URLS.includes(identifier)) {
            return <PatientRoutes />
        } else if (ADMIN_URLS.includes(identifier)) {
            return <AdminRoutes />
        } else {
            return <NotFoundRoutes />
        }
    }

    // Function fired when session is ABOUT to expire
    const onPrompt = () => {
        if (NON_EXPIRABLE_SESSION_PATHS.includes(window.location.pathname)) {
            return;
        }
        dispatch(setExpiring());
    }
    
    // Function fired when session timeout has been reached
    const onIdle = () => {
        if (NON_EXPIRABLE_SESSION_PATHS.includes(window.location.pathname)) {
            return;
        }
        dispatch(setExpired());
        invalidateCookies();
        pause();
    }
    
    // Function fired when an action was detected. 
    const onAction = () => {
        if (sessionStatus === SESSION_STATUS.EXPIRED) {
            return;
        }
        dispatch(setActive());
    }

    const onSessionExpiredCta = () => {
        invalidateCookies();
        dispatch(setUnauthorized());
        history.push("/login");
    }

    useEffect(() => {
        if (sessionStatus === SESSION_STATUS.ACTIVE) {
            activate();
        }
    }, [sessionStatus])

    const { activate, pause, getRemainingTime, } = useIdleTimer({
        onPrompt: onPrompt,
        onIdle: onIdle,
        onAction,
        timeout: SESSION_TIMEOUT_WARNING_TIME,
        promptTimeout: SESSION_EXPIRY_FROM_WARNING_TIME,
        events: [
            'keydown',
            'mousedown',
            'MSPointerDown',
            'visibilitychange'
        ],
    })

    return (
        <BrowserRouter>
            <ThemeProvider theme={themeGlobal}>
                <Box className={sessionStatus >= SESSION_STATUS.EXPIRING ? classes.blurBackground : {}}>
                    {sessionStatus >= SESSION_STATUS.EXPIRING && (
                        <ExpiredSessionModal
                            sessionStatus={sessionStatus}
                            getRemainingTime={getRemainingTime}
                            onSessionExpiredCta={onSessionExpiredCta}
                        />
                    )}
                    <SnackbarProvider 
                        maxSnack={sessionStatus === SESSION_STATUS.ACTIVE ? MAX_NOTIFICATIONS : -1}
                        classes={{
                            variantSuccess: classes.success,
                            variantError: classes.error,
                            variantWarning: classes.warning,
                            variantInfo: classes.info,
                        }}
                        iconVariant ={{
                            success:  <img width="30px" style={{paddingRight:"10px"}} src={AloeLogo} alt="Logo" />,
                            error: <ErrorOutlineIcon color = 'secondary' style={{marginRight:"10px"}}/> 
                        }}
                        style={{
                            width: "fit-content",
                        }}
                        anchorOrigin={ {horizontal: "center", vertical: "top" }} 
                    >
                        {process.env.REACT_APP_ENVMNT === 'staging' && (
                            <Box id="environment-banner" >
                                <Alert severity="info">This is a demo version of Aloe — take a look around!</Alert>
                            </Box>
                        )}
                        <Suspense fallback={<AloeLoading />}>
                            {routes()}    
                        </ Suspense>
                    </SnackbarProvider>
                </Box>
            </ThemeProvider>
        </BrowserRouter>
    )
}

export default App;