import { createTheme } from '@material-ui/core'

export const themeGlobal = createTheme({
  palette: {
    background: {
      // default: "#1e2834"
    },
    primary: {
      main: "#00AF91",
    },
    secondary: {
      main: "#EB5757"
    },
    aloeGreen: "#00AF91"
  },
  props: {
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  typography: {
    fontFamily: 'Nunito Sans',
    fontSize: 22,
    fontWeightRegular: "600",
    lineHeight: "28px"
  }
});     
