import { Box, CircularProgress, Typography} from '@material-ui/core';

const AloeLoading = ({height, loadingMessage = ""}) => {
    return (
        <Box height={height ? height : "100vh"} textAlign="center" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
			<CircularProgress />
            <Typography>
                { loadingMessage }
            </Typography>
        </Box>
    );
}

export default AloeLoading;